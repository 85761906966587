.menu-icon {
  width: 20px;
  height: 20px;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.site-layout {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.site-layout-background {
  background: #fff;
}
.side_navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar_menu_ct {
  border: none;
  height: 100%;
  width: 100% !important;
  padding-left: "20px";
  background-color: rgba(255, 255, 255, 0.2);
}
.sidebar_menu-wrapper {
  margin-top: 15px;
}

.sidebar_menu {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: #fff !important;
  height: 50px !important;
  display: flex;
  align-items: center;
}
.sidebar_menu_none {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  /* background-color: #fff !important; */
  height: 50px !important;
  display: flex;
  align-items: center;
}

.sidebar_menu span {
  color: #253f60 !important;
}
.sidebar_sub_menu {
  /* background-color: #fff !important; */
  /* height: 50px !important;
  display: flex;
  align-items: center; */
  color: red !important;
}
.sidebar_sub_menu span {
  color: red !important;
}
.navbar_tools_left {
  display: flex;
  align-items: center;
  height: 50px;
}
.navbar_tools_right {
  display: flex;
  margin-right: 20px;
  align-items: center;
}
.narbar_avatar {
  width: 20px;
  height: 20px;
  vertical-align: sub;
  border-radius: 50%;
  margin-right: 8px;
}
