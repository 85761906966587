@import "~antd/dist/antd.css";

/* .ant-menu-item-selected {
  background-color: red !important;
} */
.query_form_container {
  border: 1px solid #dedede;
  border-radius: 2px;
}
.tbl_header {
  font-size: 12px;
}
.tbl_header th {
  font-weight: 600 !important;
  font-size: 14px;
  color: #303133 !important;
  background-color: #e3e4e6 !important;
}
.tbl_header td {
  /* font-weight: 600 !important; */
  font-size: 4px !important;
  /* color: #303133 !important; */
  /* background-color: #e3e4e6 !important; */
}

.track_tbl_header {
  text-align: center;
}
th.track_tbl_header {
  font-weight: 600 !important;
  font-size: 14px;
  color: white !important;
  background-color: #4f5484 !important;
  text-align: center !important;
}
.ant-steps-item-active {
  color: #61dafb !important;
}
.custom_step {
  color: #61dafb !important;
}
.ant-steps-item-wait {
  color: #61dafb !important;
}
/* ant custom */
.ant-input[disabled] {
  background-color: #f5f5f5 !important;
}
.ant-modal-header {
  background-color: #f5f5f5;
}
.ant-form-item-explain {
  font-size: 12px;
}
/* custom tab */
.ant-tabs-tab {
  border-right: white !important;
  /* font-size: 12px !important; */
  color: #909399;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotate_up {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.rotate_down {
  -ms-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
input {
  background-color: transparent !important;
}
* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

// *::-webkit-scrollbar {
//   width: 8px;
//   -ms-overflow-style: none;
//   scrollbar-width: none;
//   display: none;
// }
// *::-webkit-scrollbar-track {
//   background: transparent;
// }
// *::-webkit-scrollbar-thumb {
//   background-color: #d3d7dc;
//   border-radius: 3px;
//   border: 1px solid transparent;
// }
/* .custom_scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #d3d7dc transparent;
  scrollbar-width: none;
} */

.ant-table {
  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
