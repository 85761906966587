.ant-popover-inner-content {
  padding: 0px;
}
.ant-popover-arrow {
  display: none;
}
.logo {
  position: relative;
  width: 100%;
  background: #131432;
  text-align: center;
  overflow: hidden;
  border-bottom: 1px solid #283777;
}
.sidebar-logo-link {
  width: 100%;
  overflow: hidden;
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 11px 0;
  color: white;
  font-size: 30px;
  font-weight: bold;
}
.sidebar-logo {
  width: 75%;
  height: auto;
  vertical-align: middle;
  border-style: none;
}
